import alemana from "./alemana.svg";
import banco_de_chile from "./banco-de-chile.svg";
import caja_los_andes from "./caja-los-andes.svg";
import clinica_adventista from "./clinica-adventista.svg";
import colmena from "./colmena.svg";
import consorcio from "./consorcio.svg";
import correos from "./correos.svg";
import costanera_center from "./costanera-center.svg";
import facebook from "./facebook.svg";
import google from "./google.svg";
import huawei from "./huawei.svg";
import latam from "./latam.svg";
import notarias from "./notarias.svg";
import nuskin from "./nuskin.svg";
import outlook from "./outlook.svg";
import parque_del_recuerdo from "./parque-del-recuerdo.svg";
import redsalud from "./redsalud.svg";
import salud from "./salud.svg";
import security from "./security.svg";
import servipag from "./servipag.svg";
import sodexo from "./sodexo.svg";
import sony from "./sony.svg";
import starken from "./starken.svg";
import success from "./success.svg";
import todos from "./todos.svg";
import vida_integra from "./vida-integra.svg";
import zeroq from "./zeroq.svg";

import afpCapital from "./afp-capital.svg";

import aguasDelValle from "./aguas-del-valle.svg";
import alemanaColor from "./alemana-color.svg";
import altoLasCondesColor from "./alto-las-condes.svg";
import caja18 from "./caja-18.svg";
import caja_los_andesColor from "./caja-los-andes-color.svg";
import clinica_adventistaColor from "./clinica-adventista-color.svg";
import colmenaColor from "./colmena-color.svg";
import consorcioColor from "./consorcio-color.svg";
import correosColor from "./correos-color.svg";
import costaneraCenterColor from "./costanera-center.svg";
import esval from "./esval.svg";
import facebookColor from "./facebook-color.svg";
import googleColor from "./google-color.svg";
import huaweiColor from "./huawei-color.svg";
import latamColor from "./latam-color.svg";
import municipalidades from "./municipalidades.svg";
import notariasColor from "./notarias-color.svg";
import nuskinColor from "./nuskin-color.svg";
import parque_del_recuerdoColor from "./parque-del-recuerdo-color.svg";
import redsaludColor from "./redsalud-color.svg";
import saludColor from "./salud-color.svg";
import securityColor from "./security-color.svg";
import servipagColor from "./servipag-color.svg";
import sodexoColor from "./sodexo-color.svg";
import sonyColor from "./sony-color.svg";
import starkenColor from "./starken-color.svg";
import todosColor from "./todos-color.svg";
import vidaIntegraColor from "./vida-integra.svg";
import zeroqColor from "./zeroq-color.svg";

import logo_1 from "./1.svg";
import logo_10 from "./10.svg";
import logo_10_color from "./10-color.svg";
import logo_11 from "./11.svg";
import logo_11_color from "./11-color.svg";
import logo_12 from "./12.svg";
import logo_12_color from "./12-color.svg";
import logo_13 from "./13.svg";
import logo_13_color from "./13-color.svg";
import logo_14 from "./14.svg";
import logo_14_color from "./14-color.svg";
import logo_15 from "./15.svg";
import logo_15_color from "./15-color.svg";
import logo_16 from "./16.svg";
import logo_16_color from "./16-color.svg";
import logo_17 from "./17.svg";
import logo_17_color from "./17.svg";
import logo_18 from "./18.svg";
import logo_18_color from "./18.svg";
import logo_19 from "./19.svg";
import logo_19_color from "./19.svg";
import logo_1_color from "./1-color.svg";
import logo_2 from "./2.svg";
import logo_20 from "./20.svg";
import logo_20_color from "./20.svg";
import logo_3 from "./3.svg";
import logo_3_color from "./3.svg";

import logo_21 from "./21.svg";
import logo_21_color from "./21.svg";
import logo_22 from "./22.svg";
import logo_22_color from "./22.svg";
import logo_23 from "./23.svg";
import logo_23_color from "./23.svg";
import logo_24 from "./24.svg";
import logo_24_color from "./24.svg";
import logo_25 from "./25.svg";
import logo_25_color from "./25.svg";
import logo_26 from "./26.svg";
import logo_26_color from "./26-color.svg";
import logo_28 from "./28.svg";
import logo_28_color from "./28-color.svg";
import logo_29 from "./29.svg";
import logo_29_color from "./29-color.svg";
import logo_30 from "./30.svg";
import logo_30_color from "./30-color.svg";

import logo_104 from "./104.svg";
import logo_104_color from "./104.svg";
import logo_2_color from "./2-color.svg";
import logo_31 from "./31.svg";
import logo_31_color from "./31.svg";
import logo_32 from "./32.svg";
import logo_32_color from "./32.svg";
import logo_33 from "./33.svg";
import logo_33_color from "./33.svg";
import logo_34 from "./34.svg";
import logo_34_color from "./34.svg";
import logo_35 from "./35.svg";
import logo_35_color from "./35.svg";
import logo_38 from "./fasa.svg";
import logo_38_color from "./fasa.svg";
import logo_4 from "./4.svg";
import logo_4_color from "./4-color.svg";
import logo_5 from "./5.svg";
import logo_5_color from "./5-color.svg";
import logo_6 from "./6.svg";
import logo_6_color from "./6-color.svg";
import logo_7 from "./7.svg";
import logo_7_color from "./7-color.svg";
import logo_8 from "./8.svg";
import logo_8_color from "./8-color.svg";
import logo_9 from "./9.svg";
import logo_9_color from "./9-color.svg";
// FIXME
// These resources should live in a separate cdn instead
// of source code!!
// Therefore this module should be only temporary

// A mapping from categories slugs to logos
export default {
	logo_3_color,
	logo_3,
	logo_1,
	logo_1_color,
	logo_2,
	logo_2_color,
	logo_4,
	logo_4_color,
	logo_5,
	logo_5_color,
	logo_6,
	logo_6_color,
	logo_7,
	logo_7_color,
	logo_8,
	logo_8_color,
	logo_9,
	logo_9_color,
	logo_10,
	logo_10_color,
	logo_11,
	logo_11_color,
	logo_12,
	logo_12_color,
	logo_13,
	logo_13_color,
	logo_14,
	logo_14_color,
	logo_15,
	logo_15_color,
	logo_16,
	logo_16_color,
	logo_17,
	logo_17_color,
	logo_18,
	logo_18_color,
	logo_19,
	logo_19_color,
	logo_20,
	logo_20_color,
	logo_21,
	logo_21_color,
	logo_22,
	logo_22_color,
	logo_23,
	logo_23_color,
	logo_24,
	logo_24_color,
	logo_25,
	logo_25_color,
	logo_26,
	logo_26_color,
	logo_28,
	logo_28_color,
	logo_29,
	logo_30,
	logo_30_color,
	logo_29_color,
	logo_31,
	logo_31_color,
	logo_32,
	logo_32_color,
	logo_33,
	logo_33_color,
	logo_34,
	logo_34_color,
	logo_35,
	logo_35_color,
	logo_38,
	logo_38_color,
	logo_104,
	logo_104_color,
	notarias,
	notariasColor,
	servipag,
	servipagColor,
	starken,
	starkenColor,
	salud,
	saludColor,
	servicios: todos,
	serviciosColor: todosColor,
	colmena,
	colmenaColor,
	security,
	securityColor,
	altoLasCondesColor,
	vidaIntegraColor,
	"afp-capital": afpCapital,
	"aguas-del-valle": aguasDelValle,
	"caja-18": caja18,
	"costanera-center": costanera_center,
	costaneraCenterColor,
	esval,
	municipalidades,
	"correos-de-chile": correos,
	"correos-de-chileColor": correosColor,
	latam,
	latamColor,
	"caja-los-andes": caja_los_andes,
	"caja-los-andesColor": caja_los_andesColor,
	"banco-de-chile": banco_de_chile,
	demos: zeroq,
	demosColor: zeroqColor,
	redsalud,
	redsaludColor,
	// The following ones don't have a category
	alemana,
	alemanaColor,
	clinica_adventista,
	clinica_adventistaColor,
	consorcio,
	consorcioColor,
	huawei,
	huaweiColor,
	nuskin,
	nuskinColor,
	parque_del_recuerdo,
	parque_del_recuerdoColor,
	sodexo,
	sodexoColor,
	sony,
	sonyColor,
	zeroq,
	zeroqColor,
	facebook,
	facebookColor,
	google,
	googleColor,
	outlook,
	success,
};
