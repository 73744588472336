// @flow

import * as R from "ramda";

export type Color =
	| "primary"
	| "primary_light"
	| "secondary"
	| "secondary_light"
	| "ternary"
	| "disabled"
	| "bg"
	| "bg_alt"
	| "fg"
	| "fg_alt"
	| "error"
	| "warning"
	| "success"
	| "icon"
	| "icon_alt";

export type Size = "xxs" | "xs" | "s" | "sm" | "m" | "mm" | "l" | "xl" | "xxl";

export type Weight =
	| "regular"
	| "medium"
	| "semibold"
	| "bold"
	| "thin"
	| "ultrabold";

export type IconSize = "s" | "m" | "l";

export type IconColor = "green" | "orange";

export type Gradient = "default" | "blue" | "green" | "orange";

export type Theme = {|
	+colors: { [Color]: string },
	+size: { [Size]: string },
	+weight: { [Weight]: string },
	+icon_size: { [IconSize]: string },
	+icons: { [IconColor]: string },
|};

const theme: Theme = {
	colors: {
		primary: "#145Ab4",
		primary_light: "#5096FA",
		primaryDark: "#0e4b9a",
		secondary: "#7E2F87",
		secondary_light: "#B747C3",
		secondaryDark: "#828182",
		ternary: "#192967",
		disabled: "#A0A0A0",
		bg: "#fff", // #F7F7F7
		bg_alt: "#F7F8F8",
		fg: "#282828",
		fg_alt: "#F7F7F7",
		error: "#E13C46",
		warning: "#F06423",
		success: "#37B473",
		icon: "#7d7c84",
		icon_alt: "#fff",
		elementBg: "#f5f5f9",
		warningAlt: "#b16161",
		blue_bg: "#096DD9",
		blue_hover: "#1890ff",
		purple_hover: "#a157b0",
		disabled_color: "#f5f5f5",
		disabled_font: "#878787",
		reservation_bg: "#592a63",
		officeBackground: "#F5F5FB",
	},
	size: {
		xxs: "0.125rem",
		xs: "0.25rem", // 4px
		s: "0.5rem", // 8px
		sm: "0.75rem", // 12px
		m: "1rem", // 16px
		mm: "1.25rem", // 20px
		l: "1.5rem", // 24px
		xl: "2rem", // 32px
		xxl: "3rem", // 48px
	},
	weight: {
		thin: "300",
		regular: "400",
		medium: "500",
		semibold: "600",
		bold: "700",
		ultrabold: "900",
	},
	icon_size: {
		s: "1.2",
		m: "1.4",
		l: "1.6",
	},
	icons: {
		green: "#1BB479",
		orange: "#F56233",
	},
	breakpoints: {
		desktop: "768px",
	},
};

const gradients = {
	default: `linear-gradient(
      229.1deg,
      ${theme.colors.primary} 0%,
      ${theme.colors.secondary} 100%
    )`,
	blue: `linear-gradient(
      46.7deg,
      #2d93db 0%,
      #145ab4 100%
    )`,
	green: `linear-gradient(
    225deg,
    #98CF36 0%,
    #429321 100%
  )`,
	orange: `linear-gradient(
    227.2deg,
    #F5C412 0%,
    #F76B1C 100%
  )`,
	shadow: `-2px 0px 12px 1px rgba(219, 219, 219, 1)`,
};

type Props = { theme: Theme };
type ThemeGetter<Y, T> = (prop: Y) => (prop: Props) => string | T;

export const getColor: ThemeGetter<Color, "#000"> =
	(color) =>
	({ theme }) =>
		// $FlowFixMe
		R.pathOr("#000", ["colors", color], theme);
export const getSize: ThemeGetter<Size, "1rem"> =
	(size) =>
	({ theme }) =>
		// $FlowFixMe
		R.pathOr("1rem", ["size", size], theme);
export const getWeight: ThemeGetter<Weight, "400"> =
	(weight) =>
	({ theme }) =>
		// $FlowFixMe
		R.pathOr("400", ["weight", weight], theme);
export const getGradient: ThemeGetter<Gradient, string> = (name) => () =>
	R.pathOr(gradients.default, [name], gradients);

type PrimaryColorBySlug = {
	[categorySlug: string]: string,
};

const palette: PrimaryColorBySlug = {
	"banco-bci": "#fff",
	vidaintegra: "#fff",
	"caja-los-andes": "#0086bf",
	colmena: "#0a74b7",
	consorcio: "#003058",
	correos: "#c12a21",
	"correos-de-chile": "#c12a21",
	"banco-de-chile": "#1b008c",
	huawei: "#d2030d",
	latam: "#1b008c",
	notarias: "#007288",
	nuskin: "#007297",
	"parque-del-recuerdo": "#006848",
	redsalud: "#00A7B6",
	salud: "#01aab9",
	security: "#823d98",
	"clinica-davila": "#fff",
	servipag: "#FFD600",
	servicios: "#823d98",
	starken: "#1ab374",
	zeroq: "#124277",
	demos: "#124277",
	municipalidades: "#124277",
	esval: "#fff",
	"caja-18": "#fff",
	"aguas-del-valle": "#fff",
	"afp-capital": "#fff",
	"costanera-center": "#fff", //
	"florida-center": "#350a5f",
	"alto-las-condes": "#000000",
	centromed: "#ec1c24",
	"la-polar": "fff",
	"chilena-consolidada": "#fff",
	"superintendencia-de-pensiones": "#fff",
	"viajes-falabella": "#fff",
	chilexpress: "#fff",
	zurich: "#fff",
	"afp-habitat": "#C90934",
	"farmacias-ahumada": "#E50E19",
	"dispensacion-mennar": "#fff",
};

export const getCategoryBackgroundColor = (categorySlug: string) => {
	return R.propOr("#823d98", categorySlug, palette);
};

export default theme;
